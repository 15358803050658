<script setup lang="ts">
import { VAppBar } from "vuetify/components/VAppBar";
import { mdiAccountCircle, mdiMenu } from "@mdi/js";

const emit = defineEmits<{
  (e: "toggle:sidebar"): void;
}>();

const { locale } = useI18n();
const { theme } = useThemeStorage();
const { isLoggedIn } = useAuthStorage();
</script>

<template>
  <VAppBar elevation="2">
    <div class="w-full flex items-center px-4">
      <MBtn
        v-show="isLoggedIn"
        icon
        color="default"
        @click="emit('toggle:sidebar')"
      >
        <MIcon :icon="mdiMenu" />
      </MBtn>
      <BtnLink
        :route="{ name: 'index' }"
        color="default"
        :active="false"
        height="3rem"
      >
        <img
          :src="`/header-${locale}-${theme}.svg`"
          class="h-10"
        />
      </BtnLink>

      <div class="flex-grow" />
      <SupportWhatsapp class="!hidden md:!inline-flex" />
      <div v-if="isLoggedIn">
        <BtnLink
          :route="{ name: 'dashboard-account' }"
          icon
          color="default"
        >
          <MIcon :icon="mdiAccountCircle" />
        </BtnLink>
      </div>
    </div>
  </VAppBar>
</template>
